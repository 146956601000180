import React, { useContext, useEffect } from "react";
import "./Footer.scss";
import { Fade } from "react-reveal";
import emoji from "react-easy-emoji";
import StyleContext from "../../contexts/StyleContext";

export default function Footer() {
  const { isDark } = useContext(StyleContext);

// trustedsite.com code start
  useEffect(() => {
    // Add TrustedSite code here
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://cdn.ywxi.net/js/1.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Clean up by removing the script when component unmounts
      document.body.removeChild(script);
    };
  }, []);
// trustedsite.com code end
  
  return (
    <Fade bottom duration={1000} distance="5px">
      <div className="footer-div">
        <p className={isDark ? "dark-mode footer-text" : "footer-text"}>
          {emoji("Made with ❤️ by K.M. NAHIDUL ISLAM")}
        </p>
      </div>
    </Fade>
  );
}
